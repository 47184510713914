<template>
	<div class="CommoditySearch">
		<w-navTab titleText="商品搜索"></w-navTab>
		<div class="header">
			<van-search v-model="title" show-action shape="round" background="#F2F2F2" placeholder="请输入商品名称" @clear='onClear'>
				<template #action>
					<div @click="onSearch">搜索</div>
				</template>
			</van-search>
		</div>
		<VantList class="list-view" :loading="loading" :finished="finished" :is-empty="isEmpty" @onLoad="onLoad">
			<div class="container-body">
				<div class="item-box" v-for="item in companyList"
					@click="$router.push({ name: 'CommodityDetail', params: { id: item.id } })">
					<div class="item-box-picture"><img :src="item.thumb" alt="" /></div>
					<div class="item-box-bottom">
						<div class="item-box-text">{{ item.title }}</div>
						<div class="item-box-price">
							<div class="price-left">
								<div class="price-title">
									<span>￥</span>{{item.price}}
								</div>
								<!-- <div class="price-text">
									￥{{item.price}}{{parseInt(item.price*100 - item.discount*100)/parseInt(100).toFixed(2)}}
								</div> -->
							</div>
							<div class="price-box">
								<div class="right-text">爱心积分{{item.integral}}</div>
								<div class="right-number">抵{{item.discount}}元</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</VantList>
	</div>
</template>

<script>
	import WTab from '@/components/Tab';
	import VantList from '@/components/VantList';
	import LoadingPage from '@/components/LoadingPage';
	import Commodity from '@/api/commodity';
	import VantVendor from '@/vendor/vant';
	export default {
		name: 'CommoditySearch',
		data() {
			return {
				page: 1,
				limit: 10,
				loading: false,
				finished: false,
				isEmpty: false,
				loadingPage: false,
				companyList: [],
				title: '',
			}
		},
		created() {
			this.$store.commit('keep/setKeep', 'CommoditySearch');
		},
		methods: {
			onSearch() {
				this.page = 1
				this.loading = true;
				this.finished = false;
				this.isEmpty = false;
				this.companyList = []
				this.onLoad()
			},
			onClear(){
				this.page = 1
				this.finished = false;
				this.isEmpty = false;
				this.companyList = []
				this.onLoad()
			},
			onLoad() {
				this.loading = true;
				let params = {
					page: this.page,
					limit: this.limit,
					title: this.title,
					classify_id: this.classify_id,
					type: this.type
				};
				Commodity.productIndex(params)
					.then(result => {
						this.page++;
						result.data.list.forEach((item, key) => {
							item.value = 0;
						});
						this.companyList = this.companyList.concat(result.data.list);

						this.loading = false;
						this.loadingPage = false;
						
						if (this.companyList.length >= result.data.total) {
							this.finished = true;
						}

						if (this.companyList.length <= 0) {
							this.isEmpty = true;
						}
						
					})
					.catch(error => {
						VantVendor.Toast.fail(error.msg);
					});
			},
		},
		components:{
			VantList
		}
	}
</script>

<style scoped lang="less">
	.CommoditySearch {
		width: 100%;
		min-height: 100vh;
		box-sizing: border-box;

		.header {
			padding-top: 5px;
			/deep/.van-search__content {
				background: #fff;
			}

			/deep/.van-search__action {
				color: rgba(51, 119, 255, 1);
			}
		}

		.container-body {
			padding: 10px;
			padding-top: 5px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex-wrap: wrap;
			background: #f2f2f2;
			padding-bottom: 0;
			box-sizing: border-box;

			.item-box {
				width: 172px;
				background: #fff;
				box-shadow: 0px 0px 8px rgba(51, 119, 255, 0.1);
				border-radius: 8px;
				overflow: hidden;
				margin-bottom: 10px;
			
				.item-box-picture {
					width: 172px;
					height: 170px;
			
					img {
						width: 100%;
						height: 100%;
					}
				}
			
				.item-box-bottom{
					padding: 10px;
					box-sizing: border-box;
					.item-box-text {
						min-height: 44px;
						font-size: 14px;
						line-height: 22px;
						color: #000;
						box-sizing: border-box;
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 2;
						overflow: hidden;
					}
					
					.item-box-price {
						margin-top: 4px;
						box-sizing: border-box;
					
						.price-left {
							display: flex;
							align-items: center;
					
							.price-title {
								font-size: 16px;
								color: #f43e3d;
					
								span {
									font-size: 12px;
								}
							}
					
							.price-text {
								padding-left: 4px;
								font-size: 12px;
								line-height: 22px;
								text-decoration-line: line-through;
								color: #909399;
							}
						}
					
						.price-box {
							display: flex;
							align-items: center;
							margin-top: 4px;
							box-sizing: border-box;
					
							.right-text {
								font-size: 10px;
								line-height: 16px;
								color: #E95749;
								padding: 0 3px 0 3px;
								border: 1px solid #E95749;
								border-right: 1px dashed #E95749;
								box-sizing: border-box;
								background: rgba(233, 87, 73, .1);
								border-top-left-radius: 2px;
								border-bottom-left-radius: 2px;
							}
					
							.right-number {
								padding: 0 3px;
								font-size: 10px;
								line-height: 16px;
								border: 1px solid #E95749;
								border-left: 0;
								color: #E95749;
								box-sizing: border-box;
								border-top-right-radius: 2px;
								border-bottom-right-radius: 2px;
							}
						}
					}
				}
			}
		}
	}
</style>